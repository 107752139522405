<template>
  <div>
    <skeleton-return-info v-show="!theReturn.id"/>
    <div v-show="show.detail">
      <b-card class="mb-0">
        <b-row>
          <b-col cols="6" md="4" lg="3" xl="3" v-for="(info, index) in mediaInfo" :key="index">
            <media-info v-bind="info"/>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" class="mt-1">
            <timeline :timeline.sync="timeline" icon="TruckIcon" type="lineal" color="warning" :showIconAllActive="false"></timeline>
          </b-col>
        </b-row>
      </b-card>
      <div class="my-1 d-flex justify-content-end">
        <b-button v-if="!!theReturn.parent_delivery_id && theReturn.parent_delivery_id !== null"
          class="mr-1" variant="warning"
          @click="gotoOriginalDelivery"
        >{{$t("Ver envío original")}}</b-button>
        <b-button
          class="mr-1" variant="warning" :disabled="disableReinyected"
          v-if="show.reinyectedButton" 
          @click="reInyectedReturnDelivery()"
        ><i class="fa fa-spinner fa-spin mr-1" aria-hidden="true" v-show="show.loadingReinyected"></i>{{$t("Reinyectar devolución")}}</b-button>
        <!-- <b-button v-if="show.changeMultipackage && [5].includes(theReturn.status.id)" variant="primary" class="mr-1" @click="openModal('modalMultiPackage')"        
        >{{$t("Cambiar a multibulto")}}</b-button> -->
        <!-- <b-button variant="primary" @click="openModal('modalIFrame')" >{{$t("Ver etiqueta")}}</b-button> -->
        <b-button class="ml-1" variant="danger" :disabled="disableForceTracking" v-show="show.forceTracking" @click="forceTracking()">
          <i class="fa fa-spinner fa-spin mr-1" aria-hidden="true" v-show="show.loadingForceTracking"></i>
          Forzar tracking
        </b-button>
        <b-button
          class="ml-1" variant="danger" v-if="show.cancelDelivery"
          @click="cancelDelivery({ id: theReturn.id, shipper: theReturn.shipper })"
        >{{$t("Cancelar envío")}}</b-button>
      </div>
      <div class="my-1 d-flex justify-content-end">
        <b-link v-if="show.changeMultipackage && [5].includes(theReturn.status.id)" class="ml-1" @click="openModal('modalMultiPackage')">{{$t("Transformar a multibulto")}}</b-link>
        <b-link v-if="showLabel" class="ml-1" @click="openModal('modalIFrame')">{{$t("Etiqueta")}}</b-link>
        <!-- <b-link v-if="showResendEmail" class="ml-1" @click="fetchResendEmail()">{{$t("Reenviar email")}}</b-link> -->
      </div>
      <b-card>
        <b-tabs align="left" class="tab-primary">
          <b-tab>
            <template #title>{{ $t("Historial de estados") }}</template>
            <b-button variant="warning" v-if="returnStatusBotton" class="mb-1" v-b-modal.modalReturnStatus><feather-icon icon="ArchiveIcon"/> {{$t("Cambiar estado")}}</b-button>
            <status-history :returnData="theReturn"></status-history>
          </b-tab>
          <b-tab>
            <template #title>{{ $t("Info de la devolución") }}</template>
            <info-return :theReturn="delivery" @watchers="emitShowers"></info-return>
          </b-tab>
          <b-tab>
            <template #title>{{ $t("Ticket") }}</template>
            <ticket-return :delivery="delivery"></ticket-return>
          </b-tab>
          <b-tab v-if="['admin', 'superadmin'].includes(role)">
            <template #title>{{ $t("Avanzado") }}</template>
            <advanced :dispatches="theReturn.dispatches"></advanced>
          </b-tab>
          <b-tab  v-if="['admin', 'superadmin'].includes(role)">
            <template #title>{{ $t("Log tracking") }}</template>
            <tracking-log></tracking-log>
          </b-tab>
        </b-tabs>
      </b-card>
      <modal-iframe :title="$t('Etiqueta')" :url.sync="labelsURL" titleColor="dark"></modal-iframe>
      <modal-multi-package :nPackages="theReturn.data && theReturn.data.order && theReturn.data.order.n_packages ? theReturn.data.order.n_packages : 1" @send="putReturnPackages" :loading="show.loadingReturnMultiPackageBotton" />
      <modal-return-status :statuses.sync="returnStatuses" :returnID="theReturn.id" @send="updateReturnStatus" :loading="show.loadingReturnStatusBotton"/>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import StatusHistory from './tabs/StatusHistory.vue'
import InfoReturn from './tabs/InfoReturn.vue'
import TicketReturn from './tabs/TicketReturn.vue'
import Advanced from './tabs/Advanced.vue'
import TrackingLog from './tabs/TrackingLog.vue'
import modalMultiPackage from './modalMultiPackage.vue'
import modalReturnStatus from './modalReturnStatus.vue'
import SkeletonReturnInfo from './SkeletonReturnInfo.vue'
import { environment } from '@/environments/environment'
import ReturnsService from './../returns.service'
import moment from 'moment-timezone'
import { BLink } from 'bootstrap-vue'

export default {
  components: { StatusHistory, InfoReturn, TicketReturn, Advanced, TrackingLog, modalMultiPackage, modalReturnStatus, SkeletonReturnInfo, BLink },
  data () {
    return {
      mediaInfo: [],
      timeline: [],
      groups: {
        CREATED : {
          status: ['CREATED', 'PENDING', 'Orden en preparación']
        },
        PICK_UP_CARRIER : {
          status: ['*', 'PICK_UP_CARRIER', 'IN_ORIGIN', 'Retirado por operador']
        },
        DISPATCHED : {
          status: ['DISPATCHED', 'PARTIALLY_DELIVERED', 'En reparto']
        },
        DELIVERED_PUDO : {
          status: ['DELIVERED_PUDO', 'Disponible para retiro']
        },
        DELIVERED : {
          status: ['DELIVERED_DOM', 'PICKED_UP', 'Entregado', 'RETURN_DELIVERED']
        }
      },
      email: null,
      returnStatuses: [],
      disableReinyected: false,
      disableForceTracking: false,
      returnStatusBotton: false,
      timezones: {
        'CL':	'America/Santiago',
        'MX':	'America/Chihuahua',
        'CO':	'America/Bogota',
        'PE':	'America/Lima',
        'AR':	'America/Buenos_Aires',
        'EC':	'America/Guayaquil',
        'US':	'America/New_York'
      },
      show: {
        detail: false,
        showDetailTicket: true,
        changeMultipackage: false,
        cancelDelivery: false,
        loadingReinyected: false,
        reinyectedButton: false,
        loadingForceTracking: false,
        forceTracking: false,
        loadingReturnMultiPackageBotton: false,
        loadingReturnStatusBotton: false
      },
      loading : {
        levels: false
      },
      services: {},
      returnsService: new ReturnsService(this)
    }
  },
  computed: {
    ...mapGetters({
      mySession: 'getSession',
      theReturn: 'getReturn',
      deliveryStatusesFull: 'getDeliveryStatusesFull',
      countries: 'getCountries',
      levels: 'getLevels'
    }),
    labelsURL () {
      return `${this.theReturn.carrier?.labels['PDF']?.url}#page=1&zoom=150`
    },
    ticket () {
      return this.theReturn.id
    },
    showLabel () {
      return !!this.theReturn.carrier?.labels
    },
    role () {
      return this.mySession?.role || null
    },
    delivery () {
      return this.theReturn?.id ? this.theReturn : {}
    }
  },
  watch: {
    theReturn () {
      this.theReturn.countryTimezone = this.timezones[this.theReturn.destination.address.country_code]
      const services = [{ name: 'getDeliveryStatusesFull' }]
      this.$store.dispatch('fetchMultipleServices', {services, onSuccess: () => this.setData()})
    },
    deliveryStatusesFull () {
      if (!!this.deliveryStatusesFull) this.returnStatuses = this.deliveryStatusesFull.map(status => ({
        ...status,
        text: status.name
      }))
    },
    mySession() {
      if (this.mySession?.id) { 
        if (this.$route.params.id) this.getReturnDelivery()
      }
    }
  },
  mounted () {
    if (this.mySession?.id) { 
      if (this.$route.params.id) this.getReturnDelivery()
    }
  },
  methods: {
    getReturnDelivery () {
      this.$store.dispatch('fetchService', { name: 'getReturn', params: {id: this.$router.currentRoute.params.id } })
    },
    setData() {
      if (['superadmin', 'admin'].includes(this.mySession?.role)) {
        // this.show.forceTracking = true
        this.returnStatusBotton = true
      } else {
        this.returnStatusBotton = ['pedro.duhalde@walmart.com', 'patricio.reyne@walmart.com', 'stephanie.osorio@walmart.com'].includes(this.mySession?.email.toLowerCase())
      }
      this.show.reinyectedButton = [1, 4].includes(this.theReturn?.status?.id)
      this.generateMediaInfo(this.theReturn)
    },
    generateTimeline (returnData) {
      this.timeline = [
        {active: returnData.status?.code === 'CREATED', stage_code: 'CREATED', title: 'Devolución preparada', date: null},
        {active: returnData.status?.code === 'PICK_UP_CARRIER', stage_code: 'PICK_UP_CARRIER', title: 'Retirado', date: null},
        {active: returnData.status?.code === 'DISPATCHED', stage_code: 'DISPATCHED', title: 'En reparto', date: null},
        {active: returnData.status?.code === 'DELIVERED', stage_code: 'DELIVERED', title: 'Devolución entregada', date: null}
      ]
      this.timeline = this.timeline.map(el => {
        const indexStatus = returnData.tracking.findIndex(status => this.groups[el.stage_code].status.includes(status.code))
        el.active = indexStatus !== -1
        return el
      })
      this.show.detail = true
    },
    generateMediaInfo (returnData) {
      this.mediaInfo = [
        {
          title:`${this.$t('Devolución')} ${returnData.carrier?.code || this.$t('Sin información') }`,
          content: `Ref: ${returnData.order?.imported_id || this.$t('Sin información')}<br>
          OT: ${returnData.carrier?.tracking_number || this.$t('Sin información')}`,
          icon: 'TruckIcon',
          variant: 'light-light'
        },
        {
          title: this.$t('Destino'),
          content: `${(returnData.destination?.address?.full_address).toLowerCase()}, ${returnData.destination?.address?.level2}`,
          icon: 'MapPinIcon',
          variant: 'light-light',
          capitalizeContent: true
        },
        {
          title: this.$t('Tiempo de tránsito'),
          content: returnData.carrier?.transit_days ? `${returnData.carrier?.transit_days} días` : this.$t('Sin información'),
          icon: 'ClockIcon',
          variant: 'light-light'
        },
        {
          title: this.$t('Fecha de compromiso'),
          content: returnData.carrier?.deadline_date ? this.$options.filters.moment(moment(returnData.carrier?.deadline_date).tz(returnData.countryTimezone), 'DD-MM-YYYY') : this.$t('Sin información'),
          icon: 'CalendarIcon',
          variant: 'light-light'
        }
      ]
      this.generateTimeline(this.theReturn)
    },
    putReturnPackages (packages) {
      const data = {
        name: 'updatePackageReturn',
        params: {
          delivery_id: this.theReturn.id,
          shipper_id: this.theReturn.shipper.id
        },
        queryParams: packages,
        onSuccess: () => { this.$success('Cambio de multibultos existoso!', () => this.$router.go(), 'Completado', 'Aceptar', false) }
      }      
      this.$store.dispatch('fetchService', data)
    },
    gotoOriginalDelivery () {
      if (this.theReturn.parent_delivery_id) {
        window.open(`${environment.platformUrl}/deliveries/${this.theReturn.parent_delivery_id}`, '_blank')
      }
    },
    reInyectedReturnDelivery () {
      const params = {
        shipper_id: this.theReturn.shipper.id,
        delivery_id: this.theReturn.id
      }
      this.show.loadingReinyected = true
      this.disableReinyected = true
      this.$store.dispatch('fetchService', 
        { name: 'deliveriesDispatch', params, 
          onSuccess: () => { this.$success('Reinyección exitosa!', () => this.$router.go(), 'Completado', 'Aceptar', false); this.show.reinyectedButton = false; this.disableReinyected = false },
          onError: () => { this.$alert('Ocurrió un problema al realizar la reinyección.'); this.show.loadingReinyected = false; this.disableReinyected = false }
        })
    },
    forceTracking () {
      const params = {
        return_delivery_id: this.theReturn.data.id
      }
      // this.show.loadingForceTracking = true
      // this.disableForceTracking = true
      // returnService.callService('forceTracking', null, params, this.fb.laravel_token)
      //   .then(response => {
      //     if (response.tracking.status) {
      //       this.show.loadingForceTracking = false
      //       this.$success('Forzar exitoso!', () => this.$router.go(), 'Completado', 'Aceptar', false)
      //     } else {
      //       this.$alert(`Ocurrió un problema al forzar tracking. ${response.tracking.message}`)
      //     }
      //     this.show.loadingForceTracking = false
      //     this.disableForceTracking = false
      //   })
      //   .catch(err => {
      //     this.show.loadingForceTracking = false
      //     this.disableForceTracking = false
      //     this.$alert('Ocurrió un problema al forzar tracking.')
      //   })
    },
    updateReturnStatus (payload) {
      this.show.loadingReturnStatusBotton = true
      this.returnsService.callService('postDeliveryStatus', payload, { shipper_id: this.theReturn.shipper.id, delivery_id: payload.id })
        .then(response => {
          if (response.code === 'success') {
            this.show.loadingReturnStatusBotton = false
            this.$bvModal.hide('modalReturnStatus')
            this.$success('Cambio de estado existoso!', () => this.$router.go(), 'Completado', 'Aceptar', false)
          } else {
            this.show.loadingReturnStatusBotton = false
            this.$bvModal.hide('modalReturnStatus')
            this.$alert('Ocurrió un problema al cambiar el estado.')
          }
        })
        .catch(err => {
          this.show.loadingReturnStatusBotton = false
          this.$alert('Ocurrió un problema al cambiar el estado.')
          this.$bvModal.hide('modalReturnStatus')
          console.error(err)
        })
    },
    openModal(name) {
      this.$bvModal.show(name)
    },
    cancelDelivery(delivery) {
      const data = { 
        name: 'cancelDeliveriesFlash',
        params: { shipper_id: delivery.shipper.id, delivery_id: delivery.id },
        onSuccess: () => { this.$success(this.$t('msg-exito-cancelar-elemento')); setTimeout('2000', this.getReturnDelivery()) }
      }
      this.$store.dispatch('fetchService', data)
    },
    emitShowers (values) {
      Object.keys(values).map(key => this.show[key] = values[key]) 
    }
  }
}
</script>
<style lang="scss">
  
</style>